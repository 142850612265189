<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card class="ml-6" outlined>
          <v-card-text class="text-center text-subtitle-1">{{ obrano }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <validation-observer ref="observer" v-slot="{ passes }">
      <v-form @submit.prevent="passes(submitForm)">
        <v-row>
          <v-col cols="12" sm="6" md="2">
            <v-menu
              ref="menu"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  name="dataobra"
                  rules="required"
                >
                  <v-text-field
                    :value="dateFormatted"
                    :label="`${$t('fields.dataobra')} *`"
                    :prepend-inner-icon="icons.mdiCalendar"
                    :hint="$t('wot.formats.date')"
                    persistent-hint
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="errors"
                    outlined
                    dense
                  />
                </validation-provider>
              </template>
              <v-date-picker
                v-model="form.dataobra"
                @input="menu = false"
                no-title
                show-adjacent-months
                first-day-of-week="1"
              >
                <v-btn
                  text
                  block
                  color="primary"
                  @click="clickToday2()"
                >
                  {{ $t('today') }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <validation-provider v-slot="{ errors }" name="clstamp" rules="required">
              <v-autocomplete
                v-model="form.clstamp"
                :items="clients"
                item-text="nome"
                item-value="clstamp"
                :label="`${$t('fields.client')} *`"
                :prepend-inner-icon="icons.mdiAccountSearch"
                :error-messages="errors"
                outlined
                dense
                @change="changeClient"
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <validation-provider v-slot="{ errors }" name="u_cepos" rules="required">
              <v-combobox
                v-model="form.u_cepos"
                :items="uCeposOptions"
                :label="`${$t('fields.u_cepos')} *`"
                :prepend-inner-icon="icons.mdiNumeric"
                :error-messages="errors"
                outlined
                dense
                clearable
              ></v-combobox>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <validation-provider v-slot="{ errors }" name="u_tipos" rules="required">
              <v-autocomplete
                v-model="form.u_tipos"
                :items="cargoTypes"
                item-text="design"
                item-value="ststamp"
                :label="`${$t('fields.u_tipos')} *`"
                :prepend-inner-icon="icons.mdiTruck"
                :error-messages="errors"
                outlined
                dense
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <validation-provider v-slot="{ errors }" name="u_quant2" rules="max_value:99999">
              <v-text-field
                v-model.number="form.u_quant2"
                type="number"
                :label="$t('fields.u_quant2')"
                :prepend-inner-icon="icons.mdiWeightKilogram"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <validation-provider v-slot="{ errors }" name="unidade" rules="required">
              <v-autocomplete
                v-model="form.unidade"
                :items="units"
                :label="`${$t('fields.unidade')} *`"
                :prepend-inner-icon="icons.mdiAlphaUCircleOutline"
                :error-messages="errors"
                outlined
                dense
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <validation-provider v-slot="{ errors }" name="u_transp" rules="required">
              <v-autocomplete
                v-model="form.u_transp"
                :items="transporters"
                item-text="nome"
                item-value="nome"
                :label="`${$t('fields.u_transp')} *`"
                :prepend-inner-icon="icons.mdiCardAccountDetailsOutline"
                :error-messages="errors"
                outlined
                dense
                clearable
                @change="changeTransporter"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <validation-provider v-slot="{ errors }" name="u_matric" rules="required">
              <v-autocomplete
                v-model="form.u_matric"
                :items="platesOptions"
                :label="`${$t('fields.u_matric')} *`"
                :prepend-inner-icon="icons.mdiTruck"
                :error-messages="errors"
                outlined
                dense
                clearable
                @change="changePlate"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <validation-provider v-slot="{ errors }" name="u_reboq" rules="required">
              <v-autocomplete
                v-model="form.u_reboq"
                :items="trailers"
                :label="`${$t('fields.u_reboq')} *`"
                :prepend-inner-icon="icons.mdiTruckTrailer"
                :error-messages="errors"
                outlined
                dense
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <validation-provider v-slot="{ errors }" name="u_carga" rules="required">
              <v-autocomplete
                v-model="form.u_carga"
                :items="addresses"
                :label="`${$t('fields.u_carga')} *`"
                :prepend-inner-icon="icons.mdiMapMarker"
                :error-messages="errors"
                outlined
                dense
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <validation-provider v-slot="{ errors }" name="u_carga2">
              <v-text-field
                v-model="form.u_carga2"
                :label="$t('fields.u_carga2')"
                :prepend-inner-icon="icons.mdiMapMarker"
                :error-messages="errors"
                outlined
                counter="120"
                maxlength="120"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <validation-provider v-slot="{ errors }" name="u_descarga" rules="required">
              <v-autocomplete
                v-model="form.u_descarga"
                :items="addresses"
                :label="`${$t('fields.u_descarga')} *`"
                :prepend-inner-icon="icons.mdiMapMarkerOutline"
                :error-messages="errors"
                outlined
                dense
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <validation-provider v-slot="{ errors }" name="u_descarg2">
              <v-text-field
                v-model="form.u_descarg2"
                :label="$t('fields.u_descarg2')"
                :prepend-inner-icon="icons.mdiMapMarkerOutline"
                :error-messages="errors"
                outlined
                counter="120"
                maxlength="120"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="6" sm="4" md="3" lg="2">
            <validation-provider v-slot="{ errors }" name="u_fttrans" rules="max_value:99999">
              <v-text-field
                v-model.number="form.u_fttrans"
                type="number"
                :label="$t('fields.u_fttrans')"
                :append-outer-icon="icons.mdiReload"
                @click:append-outer="clickCalculatePrices"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="6" sm="4" md="3" lg="2">
            <validation-provider v-slot="{ errors }" name="u_prtransp" rules="max_value:99999">
              <v-text-field
                v-model.number="form.u_prtransp"
                type="number"
                :label="$t('fields.u_prtransp')"
                :append-outer-icon="icons.mdiReload"
                @click:append-outer="clickCalculatePrices"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" v-if="$vuetify.breakpoint.xsOnly">
            <v-btn
              color="primary"
              @click="submitForm()"
              :loading="isLoading" :disabled="isLoading"
            >
              {{ createBtn }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <validation-provider v-slot="{ errors }" name="u_mata">
              <v-autocomplete
                v-model="form.u_mata"
                :items="woods"
                :label="$t('fields.u_mata')"
                :error-messages="errors"
                outlined
                dense
                clearable
                @change="changeWoods"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <validation-provider v-slot="{ errors }" name="u_nmfornec">
              <v-autocomplete
                v-model="form.u_nmfornec"
                :items="providers"
                item-text="nome"
                item-value="nome"
                :label="$t('fields.u_nmfornec')"
                :prepend-inner-icon="icons.mdiAccount"
                :error-messages="errors"
                outlined
                dense
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="6" sm="4" md="3" lg="2">
            <validation-provider v-slot="{ errors }" name="u_prcl" rules="max_value:99999">
              <v-text-field
                v-model.number="form.u_prcl"
                type="number"
                :label="$t('fields.u_prcl')"
                :append-outer-icon="icons.mdiReload"
                @click:append-outer="clickCalculatePrices"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="6" sm="4" md="3" lg="2">
            <validation-provider v-slot="{ errors }" name="u_bonus" rules="max_value:99999">
              <v-text-field
                v-model.number="form.u_bonus"
                type="number"
                :label="$t('fields.u_bonus')"
                :error-messages="errors"
                outlined
                dense
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col
            v-if="form.u_bonus >= 0 && typeof form.u_bonus === 'number'"
            cols="12"
            sm="6"
            md="2"
          >
            <validation-provider
              v-slot="{ errors }"
              name="lobs"
              :rules="{ required: form.u_bonus >= 0 && typeof form.u_bonus === 'number' }"
            >
              <v-combobox
                v-model="form.lobs"
                :items="['FSC100%', 'SURE']"
                :label="`${$t('fields.lobs')} *`"
                :prepend-inner-icon="icons.mdiCertificate"
                :error-messages="errors"
                outlined
                dense
                clearable
              ></v-combobox>
            </validation-provider>
          </v-col>
          <v-col
            v-if="form.u_bonus >= 0 && typeof form.u_bonus === 'number'"
            cols="12"
            sm="6"
            md="4"
          >
            <validation-provider
              v-slot="{ errors }"
              name="u_mataalt"
              :rules="{ required: form.u_bonus >= 0 && typeof form.u_bonus === 'number' }"
            >
              <v-autocomplete
                v-model="form.u_mataalt"
                :items="certifiedWoods"
                :label="`${$t('fields.u_mataalt')} *`"
                :error-messages="errors"
                outlined
                dense
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="6" sm="4" md="3" lg="2">
            <validation-provider v-slot="{ errors }" name="u_comiss" rules="max_value:99999">
              <v-text-field
                v-model.number="form.u_comiss"
                type="number"
                :label="$t('fields.u_comiss')"
                :append-outer-icon="icons.mdiReload"
                @click:append-outer="clickCalculatePrices"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <validation-provider v-slot="{ errors }" name="u_equipa1">
              <v-autocomplete
                v-model="form.u_equipa1"
                :items="teams"
                item-text="nome"
                item-value="nome"
                :label="$t('fields.u_equipa1')"
                :prepend-inner-icon="icons.mdiAccountGroup"
                :error-messages="errors"
                outlined
                dense
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <validation-provider v-slot="{ errors }" name="u_tarefa1">
              <v-autocomplete
                v-model="form.u_tarefa1"
                :items="tasks"
                :label="$t('fields.u_tarefa1')"
                :prepend-inner-icon="icons.mdiCalendarCheck"
                :error-messages="errors"
                outlined
                dense
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="6" sm="4" md="3" lg="2">
            <validation-provider v-slot="{ errors }" name="u_prc1" rules="max_value:99999">
              <v-text-field
                v-model.number="form.u_prc1"
                type="number"
                :label="$t('fields.u_prc1')"
                :append-outer-icon="icons.mdiReload"
                @click:append-outer="clickCalculatePrices"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <validation-provider v-slot="{ errors }" name="u_equipa2">
              <v-autocomplete
                v-model="form.u_equipa2"
                :items="teams"
                item-text="nome"
                item-value="nome"
                :label="$t('fields.u_equipa2')"
                :prepend-inner-icon="icons.mdiAccountGroup"
                :error-messages="errors"
                outlined
                dense
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <validation-provider v-slot="{ errors }" name="u_tarefa2">
              <v-autocomplete
                v-model="form.u_tarefa2"
                :items="tasks"
                :label="$t('fields.u_tarefa2')"
                :prepend-inner-icon="icons.mdiCalendarCheck"
                :error-messages="errors"
                outlined
                dense
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="6" sm="4" md="3" lg="2">
            <validation-provider v-slot="{ errors }" name="u_prc2" rules="max_value:99999">
              <v-text-field
                v-model.number="form.u_prc2"
                type="number"
                :label="$t('fields.u_prc2')"
                :append-outer-icon="icons.mdiReload"
                @click:append-outer="clickCalculatePrices"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <validation-provider v-slot="{ errors }" name="u_equipa3">
              <v-autocomplete
                v-model="form.u_equipa3"
                :items="teams"
                item-text="nome"
                item-value="nome"
                :label="$t('fields.u_equipa3')"
                :prepend-inner-icon="icons.mdiAccountGroup"
                :error-messages="errors"
                outlined
                dense
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <validation-provider v-slot="{ errors }" name="u_tarefa3">
              <v-autocomplete
                v-model="form.u_tarefa3"
                :items="tasks"
                :label="$t('fields.u_tarefa3')"
                :prepend-inner-icon="icons.mdiCalendarCheck"
                :error-messages="errors"
                outlined
                dense
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="6" sm="4" md="3" lg="2">
            <validation-provider v-slot="{ errors }" name="u_prc3" rules="max_value:99999">
              <v-text-field
                v-model.number="form.u_prc3"
                type="number"
                :label="$t('fields.u_prc3')"
                :append-outer-icon="icons.mdiReload"
                @click:append-outer="clickCalculatePrices"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <validation-provider v-slot="{ errors }" name="u_agent">
              <v-autocomplete
                v-model="form.u_agent"
                :items="agents"
                item-text="nome2"
                item-value="nome2"
                :label="$t('fields.u_agent')"
                :prepend-inner-icon="icons.mdiHandCoinOutline"
                :error-messages="errors"
                outlined
                dense
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="6" sm="4" md="3" lg="2">
            <validation-provider v-slot="{ errors }" name="u_kickback" rules="max_value:99999">
              <v-text-field
                v-model.number="form.u_kickback"
                type="number"
                :label="$t('fields.u_kickback')"
                :append-outer-icon="icons.mdiReload"
                @click:append-outer="clickCalculatePrices"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <validation-provider v-slot="{ errors }" name="u_interme2">
              <v-autocomplete
                v-model="form.u_interme2"
                :items="agents"
                item-text="nome2"
                item-value="nome2"
                :label="$t('fields.u_interme2')"
                :prepend-inner-icon="icons.mdiHandCoinOutline"
                :error-messages="errors"
                outlined
                dense
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="6" sm="4" md="3" lg="2">
            <validation-provider v-slot="{ errors }" name="u_vconsul2" rules="max_value:99999">
              <v-text-field
                v-model.number="form.u_vconsul2"
                type="number"
                :label="$t('fields.u_vconsul2')"
                :append-outer-icon="icons.mdiReload"
                @click:append-outer="clickCalculatePrices"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <validation-provider v-slot="{ errors }" name="u_interme3">
              <v-autocomplete
                v-model="form.u_interme3"
                :items="agents"
                item-text="nome2"
                item-value="nome2"
                :label="$t('fields.u_interme3')"
                :prepend-inner-icon="icons.mdiHandCoinOutline"
                :error-messages="errors"
                outlined
                dense
                clearable
              />
            </validation-provider>
          </v-col>
          <v-col cols="6" sm="4" md="3" lg="2">
            <validation-provider v-slot="{ errors }" name="u_vconsul3" rules="max_value:99999">
              <v-text-field
                v-model.number="form.u_vconsul3"
                type="number"
                :label="$t('fields.u_vconsul3')"
                :append-outer-icon="icons.mdiReload"
                @click:append-outer="clickCalculatePrices"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="py-0">
            <v-checkbox
              v-model="form.logi2"
              :label="$t('hasTeams')"
            />
          </v-col>
        </v-row>
        <v-row v-if="bostamp">
          <v-col cols="12" sm="6" md="3" xl="2">
            <validation-provider v-slot="{ errors }" name="u_fttransp">
              <v-text-field
                v-model="form.u_fttransp"
                :label="$t('fields.u_fttransp')"
                :prepend-inner-icon="icons.mdiReceipt"
                :error-messages="errors"
                outlined
                counter="15"
                maxlength="15"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="3" xl="2">
            <validation-provider v-slot="{ errors }" name="u_ftfornec">
              <v-text-field
                v-model="form.u_ftfornec"
                :label="$t('fields.u_ftfornec')"
                :prepend-inner-icon="icons.mdiReceipt"
                :error-messages="errors"
                outlined
                counter="15"
                maxlength="15"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="3" xl="2">
            <validation-provider v-slot="{ errors }" name="u_ftequipa">
              <v-text-field
                v-model="form.u_ftequipa"
                :label="$t('fields.u_ftequipa')"
                :prepend-inner-icon="icons.mdiReceipt"
                :error-messages="errors"
                outlined
                counter="15"
                maxlength="15"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="3" xl="2">
            <validation-provider v-slot="{ errors }" name="u_ftequip2">
              <v-text-field
                v-model="form.u_ftequip2"
                :label="$t('fields.u_ftequip2')"
                :prepend-inner-icon="icons.mdiReceipt"
                :error-messages="errors"
                outlined
                counter="15"
                maxlength="15"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="3" xl="2">
            <validation-provider v-slot="{ errors }" name="u_ftequip3">
              <v-text-field
                v-model="form.u_ftequip3"
                :label="$t('fields.u_ftequip3')"
                :prepend-inner-icon="icons.mdiReceipt"
                :error-messages="errors"
                outlined
                counter="15"
                maxlength="15"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="3" xl="2">
            <validation-provider v-slot="{ errors }" name="u_uftagent">
              <v-text-field
                v-model="form.u_uftagent"
                :label="$t('fields.u_uftagent')"
                :prepend-inner-icon="icons.mdiReceipt"
                :error-messages="errors"
                outlined
                counter="15"
                maxlength="15"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="3" xl="2">
            <validation-provider v-slot="{ errors }" name="u_ftagent2">
              <v-text-field
                v-model="form.u_ftagent2"
                :label="$t('fields.u_ftagent2')"
                :prepend-inner-icon="icons.mdiReceipt"
                :error-messages="errors"
                outlined
                counter="15"
                maxlength="15"
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="3" xl="2">
            <validation-provider v-slot="{ errors }" name="u_ftagent3">
              <v-text-field
                v-model="form.u_ftagent3"
                :label="$t('fields.u_ftagent3')"
                :prepend-inner-icon="icons.mdiReceipt"
                :error-messages="errors"
                outlined
                counter="15"
                maxlength="15"
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <template v-if="!bostamp">
            <v-col cols="12" sm="6" md="2">
              <v-menu
                v-if="expeditionDate"
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider
                    v-slot="{ errors }"
                    name="expeditionDate"
                    :rules="{ required: generateAtCode }"
                  >
                    <v-text-field
                      :value="expeditionDateFormatted"
                      :label="`${$t('fields.expeditionDate')} *`"
                      :prepend-inner-icon="icons.mdiCalendar"
                      :hint="$t('wot.formats.date')"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="errors"
                      outlined
                      dense
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="expeditionDate"
                  :min="minDate"
                  @input="menu = false"
                  no-title
                  show-adjacent-months
                  first-day-of-week="1"
                >
                  <v-btn
                    text
                    block
                    color="primary"
                    @click="clickToday()"
                  >
                    {{ $t('today') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <validation-provider
                v-if="expeditionHour"
                v-slot="{ errors }"
                name="expeditionHour"
                :rules="{ regex: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, required: generateAtCode }"
              >
                <v-text-field
                  v-model="expeditionHour"
                  :label="`${$t('fields.expeditionHour')} *`"
                  v-mask="'##:##'"
                  :prepend-inner-icon="icons.mdiClock"
                  :hint="$t('wot.formats.hourMin')"
                  persistent-hint
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider>
            </v-col>
          </template>
          <v-col cols="12" v-if="$vuetify.breakpoint.smAndUp">
            <v-btn
              color="primary"
              @click="openHasTeamsDialog()"
              :loading="isLoading" :disabled="isLoading"
            >
              {{ createBtn }}
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              v-if="!bostamp"
              color="primary"
              @click="openHasTeamsDialog(true)"
              :loading="isLoading"
              :disabled="isLoading"
            >
              {{ $t('createWGt') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
    <email-dialog
      v-if="emailDialog"
      :item="loadOrder"
    />
    <v-dialog
      v-model="hasTeamsDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('hasTeams') }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="clickHasTeams(false)">{{ $t('no') }}</v-btn>
          <v-btn text color="primary" @click="clickHasTeams(true)">{{ $t('yes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { VueMaskDirective } from 'v-mask';
import {
  mdiAccountSearch,
  mdiNumeric,
  mdiAlphaUCircleOutline,
  mdiTruck,
  mdiCardAccountDetailsOutline,
  mdiTruckTrailer,
  mdiMapMarker,
  mdiMapMarkerOutline,
  mdiWeightKilogram,
  mdiPineTree,
  mdiAccount,
  mdiAccountGroup,
  mdiCalendarCheck,
  mdiCurrencyEur,
  mdiReceipt,
  mdiCalendar,
  mdiClock,
  mdiReload,
  mdiHandCoinOutline,
  mdiCertificate,
} from '@mdi/js';
import pick from 'object.pick';
import dayjs from '@/plugins/dayjs';
import functions from '@/resources/functions';

export default {
  metaInfo() {
    return {
      title: this.obrano,
    };
  },
  mixins: [functions],
  directives: { mask: VueMaskDirective },
  components: {
    ValidationProvider,
    ValidationObserver,
    EmailDialog: () => import(/* webpackChunkName: "email" */ './EmailDialog.vue'),
  },
  props: {
    bostamp: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    hasTeamsDialog: false,
    generateAtCode: false,
    menu: false,
    menu2: false,
    minDate: dayjs().toISOString(),
    form: {
      clstamp: null,
      u_cepos: null,
      unidade: null,
      u_tipos: null,
      u_transp: null,
      u_reboq: null,
      u_matric: null,
      u_carga: null,
      u_carga2: null,
      u_descarga: null,
      u_descarg2: null,
      u_quant2: null,
      u_nmfornec: null,
      u_mata: null,
      u_equipa1: null,
      u_tarefa1: null,
      u_equipa2: null,
      u_tarefa2: null,
      u_equipa3: null,
      u_tarefa3: null,
      u_fttrans: null,
      u_prc1: null,
      u_prc2: null,
      u_prc3: null,
      u_prcl: null,
      u_bonus: null,
      u_prtransp: null,
      u_comiss: null,
      u_fttransp: null,
      u_ftfornec: null,
      u_ftequipa: null,
      u_ftequip2: null,
      u_ftequip3: null,
      dataobra: null,
      u_agent: null,
      u_interme2: null,
      u_interme3: null,
      u_kickback: null,
      u_vconsul2: null,
      u_vconsul3: null,
      lobs: null,
      u_mataalt: null,
      logi2: false,
      u_uftagent: null,
      u_ftagent2: null,
      u_ftagent3: null,
    },
    icons: {
      mdiAccountSearch,
      mdiNumeric,
      mdiAlphaUCircleOutline,
      mdiTruck,
      mdiCardAccountDetailsOutline,
      mdiTruckTrailer,
      mdiMapMarker,
      mdiMapMarkerOutline,
      mdiWeightKilogram,
      mdiPineTree,
      mdiAccount,
      mdiAccountGroup,
      mdiCalendarCheck,
      mdiCurrencyEur,
      mdiReceipt,
      mdiCalendar,
      mdiClock,
      mdiReload,
      mdiHandCoinOutline,
      mdiCertificate,
    },
  }),
  computed: {
    ...mapFields('loadOrders', ['formCertified', 'nomquina', 'certifiedWoods']),
    ...mapGetters(['isLoading']),
    ...mapGetters('clients', ['clients']),
    ...mapGetters('loadOrders', ['transporters', 'cargoTypes', 'units', 'providers', 'teams', 'woods', 'tasks', 'plates', 'platesAll', 'trailers', 'uploadAddresses', 'downloadAddresses', 'loadOrder', 'loadOrderErrors', 'woodProvider', 'formPrices', 'createdBostamp', 'formExpeditionDate', 'formExpeditionHour', 'transporterPlate', 'emailDialog', 'newOrderObrano', 'teamPrices', 'woodAgent']),
    obrano() {
      const { boano, obrano } = this.loadOrder;

      if (this.bostamp) {
        return `${this.$tc('loadOrder', 1)} - ${boano}/${obrano}`;
      }

      return `${this.$tc('loadOrder', 1)} - ${this.newOrderObrano}`;
    },
    createBtn() {
      return this.bostamp ? this.$t('save') : this.$t('create');
    },
    dateFormatted() {
      return this.formatDate(this.form.dataobra);
    },
    agents() {
      return this.providers.filter(({ nome2 }) => nome2 !== '');
    },
    expeditionDateFormatted() {
      return this.formatDate(this.expeditionDate);
    },
    expeditionDate: {
      get() {
        return this.formExpeditionDate;
      },
      set(value) {
        this.setFormExpeditionDate(value);
      },
    },
    expeditionHour: {
      get() {
        return this.formExpeditionHour;
      },
      set(value) {
        this.setFormExpeditionHour(value);
      },
    },
    addresses() {
      return this.downloadAddresses.concat(this.uploadAddresses);
    },
    platesOptions() {
      return this.form.u_transp
        ? [this.form.u_matric, ...this.plates]
        : this.platesAll;
    },
  },
  methods: {
    ...mapActions(['fetchServerTime']),
    ...mapActions('loadOrders', ['showDownloadAddresses', 'showPlatesAll', 'showPlates', 'showWoods', 'createLoadOrder', 'showLoadOrder', 'editLoadOrder', 'showWoodProvider', 'calculateFormPrices', 'createDeliveryNote', 'showTransporterPlate', 'showTrailers', 'showAttachments', 'showNewOrderObrano', 'showWoodAgent', 'showMaxNomquinaClstamp', 'showCertifiedWoods']),
    ...mapMutations(['setLoading']),
    ...mapMutations('loadOrders', ['setEmailDialog', 'setFormExpeditionDate', 'setFormExpeditionHour']),
    clickToday() {
      this.expeditionDate = dayjs().format('YYYY-MM-DD');
    },
    clickToday2() {
      this.form.dataobra = dayjs().format('YYYY-MM-DD');
    },
    async openHasTeamsDialog(generateAtCode = false) {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.generateAtCode = generateAtCode;

        this.hasTeamsDialog = true;
      }
    },
    async clickHasTeams(logi2) {
      this.form.logi2 = logi2;

      await this.submitForm();

      this.hasTeamsDialog = false;
    },
    async changeTransporter(u_transp) {
      if (u_transp) {
        this.setLoading(true);

        await this.showPlates(u_transp);
        await this.showTrailers(u_transp);

        this.setLoading(false);
      }
    },
    async changeClient(clstamp) {
      if (clstamp) {
        this.setLoading(true);

        await this.showDownloadAddresses(clstamp);

        const client = this.clients.find((element) => element.clstamp === clstamp);

        if (!this.bostamp) {
          if (client.u_notaent) {
            await this.showMaxNomquinaClstamp(clstamp);

            this.form.u_cepos = null;

            const yy = dayjs(this.form.dataobra).format('YY');

            this.$set(this.uCeposOptions, 1, `NE-S ${yy} ${client.no}/${this.nomquina + 1}`);
          } else {
            this.$set(this.uCeposOptions, 1, 'NE-S');
          }
        }

        this.setLoading(false);
      }
    },
    async changeWoods(u_mata) {
      this.setLoading(true);

      if (u_mata) {
        await this.showWoodProvider(u_mata);

        this.form.u_nmfornec = this.woodProvider?.nome;

        await this.showWoodAgent(u_mata);

        this.form.u_agent = this.woodAgent?.nome;
      }

      this.setLoading(false);
    },
    async changePlate(u_matric) {
      if (u_matric) {
        this.setLoading(true);

        await this.showTransporterPlate(u_matric);

        this.form.u_transp = this.transporterPlate;

        this.changeTransporter();

        this.setLoading(false);
      }
    },
    async clickCalculatePrices() {
      this.setLoading(true);

      try {
        await this.calculateFormPrices(pick(this.form, [
          'clstamp',
          'u_tipos',
          'u_equipa1',
          'u_tarefa1',
          'u_mata',
          'u_equipa2',
          'u_tarefa2',
          'unidade',
          'u_descarga',
          'u_carga',
          'u_transp',
          'u_nmfornec',
          'u_agent',
          'u_equipa3',
          'u_tarefa3',
        ]));

        this.form = { ...this.form, ...this.formPrices };

        this.$refs.observer.reset();
      } catch (e) {
        console.log(e);
      }

      this.setLoading(false);
    },
    async submitForm() {
      this.setLoading(true);

      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        if (this.bostamp) {
          await this.editLoadOrder({ bostamp: this.bostamp, ...this.form });
        } else {
          try {
            await this.createLoadOrder(this.form);

            if (this.generateAtCode) {
              await this.createDeliveryNote({
                expeditionDate: this.expeditionDate,
                expeditionHour: this.expeditionHour,
                bostamp: this.createdBostamp,
              });
            }

            await this.showLoadOrder(this.createdBostamp);

            await this.showAttachments(this.createdBostamp);

            this.setEmailDialog(true);

            Object.keys(this.form).forEach((k) => { this.form[k] = null; });

            await this.fetchServerTime();

            this.form.dataobra = this.expeditionDate;
          } catch (e) {
            console.log(e);
          }
        }
      }

      this.setLoading(false);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.u_nmfornec': function (newVal) {
      this.showWoods({ u_nmfornec: newVal, u_agent: this.form.u_agent });
    },
    // eslint-disable-next-line func-names
    'form.u_agent': function (newVal) {
      this.showWoods({ u_agent: newVal, u_nmfornec: this.form.u_nmfornec });
    },
    loadOrderErrors() {
      this.$refs.observer.setErrors(this.loadOrderErrors);
    },
  },
  async mounted() {
    this.setLoading(true);

    await this.fetchServerTime();

    await this.showPlatesAll();

    await this.showCertifiedWoods();

    if (this.bostamp) {
      await this.showLoadOrder(this.bostamp);

      const dataobra = this.formatUnixToDate(this.loadOrder.dataobra);

      this.form = { ...this.form, ...this.loadOrder, dataobra };
    } else {
      await this.showNewOrderObrano();

      this.form.dataobra = this.expeditionDate;
    }

    this.setLoading(false);
  },
};
</script>
